function menu() {
  var element = document.getElementsByClassName('nav')[0];

  if (element.className.indexOf('hidden') > 0) {
    element.className = element.className.replace('hidden', 'visible');
    setTimeout(function () {
      element.className = element.className.replace('start', 'end');
    }, 100);
  } else {
    element.className = element.className.replace('end', 'start');
    setTimeout(function () {
      element.className = element.className.replace('visible', 'hidden');
    }, 200);
  }
}

function getAspectRatio(width, height, maxWidth, maxHeight) {
  var ratio = Math.min(maxWidth / width, maxHeight / height);
  return { w: width * ratio, h: height * ratio };
}
/**
 * Shuffles array in place
 */
function shuffle(array) {
  var counter = array.length,
    index,
    temp;
  while (counter > 0) {
    index = Math.floor(Math.random() * counter);
    counter--;
    temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

// desktop, tablet, mobile
window.breakpoint = 'desktop';
function calculateBreakpointVar() {
  var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  // var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  if (width > 1200) {
    window.breakpoint = 'desktop';
  } else if (width >= 720) {
    window.breakpoint = 'tablet';
  } else {
    window.breakpoint = 'mobile';
  }
}

window.onload = function () {
  document.getElementsByClassName('burger')[0].addEventListener('click', menu);
  document.getElementsByClassName('nav')[0].addEventListener('click', menu);

  /**
   * Sharing page scripts
   */
  (function () {
    var resizeHandlers = [];

    var img = document.getElementById('share-photo-mobile');
    var imgContainer = document.getElementById('share-photo-container-mobile');
    if (img && imgContainer) {
      resizeHandlers.push(function () {
        var size = getAspectRatio(img.naturalWidth, img.naturalHeight, imgContainer.clientWidth, imgContainer.clientHeight);
        img.style.height = size.h + 'px';
        // adjust title
        img.nextElementSibling.style.left = Math.round((imgContainer.clientWidth - size.w) / 2 + 20).toString() + 'px';
        img.nextElementSibling.style.bottom = Math.round((imgContainer.clientHeight - size.h) / 2 + 20).toString() + 'px';
        img.style.visibility = 'initial';
      });
    }

    var dImg = document.getElementById('share-photo-desktop');
    var dImgContainer = document.getElementById('share-photo-container-desktop');
    if (dImg && dImgContainer) {
      resizeHandlers.push(function () {
        var size = getAspectRatio(dImg.naturalWidth, dImg.naturalHeight, dImgContainer.clientWidth, dImgContainer.clientHeight);
        if (size.w <= 0 || size.h <= 0)
          return;
        if (size.h > size.w) {
          dImg.style.height = size.h + 'px';
          dImg.style.width = 'auto';
        } else {
          dImg.style.height = 'auto';
          dImg.style.width = size.w + 'px';
        }
        dImg.style.visibility = 'initial';
      });
    }
    function onResizeGlobal() {
      for (var i = 0; i < resizeHandlers.length; ++i) {
        resizeHandlers[i]();
      }

      // update breakpoint
      calculateBreakpointVar();
    }
    window.addEventListener('resize', onResizeGlobal);
    onResizeGlobal();
    setTimeout(onResizeGlobal, 10);
  })();

  /**
   * Random cards on landing
   */
  (function () {
    var cardsWrapper = document.getElementById('cwrapper');
    if (cardsWrapper
      && window.cardsFemales) {
      var cardElements = cardsWrapper.querySelectorAll('.card');
      // pick random cards and place them
      var cardsCount = cardElements.length;
      var femalesCount = Math.ceil(cardsCount / 2);

      var lang = 'en';
      if (window.renderOptions
        && typeof window.renderOptions === 'object'
        && window.renderOptions.lang) {
        lang = window.renderOptions.lang;
      }

      var cardsIndices = Array(cardsCount).fill()
        .map(function (__, m) {
          return m + 1;
        });
      window.cardsFemales = shuffle(window.cardsFemales);
      window.cardsMales = shuffle(window.cardsMales);

      var i,
        person,
        cardElem,
        cardNameElem,
        cardsArray,
        randomPos,
        cardIndex,
        genderSuffix,
        photoPath;

      var overlay = document.getElementById('preload-overlay'),
        imageLoadStatus = {},
        overlayRemoved = false;

      function loadOtherImages() {
        var i;
        var stepImages = document.querySelectorAll('.use-step-image');
        for (i = 0; i < stepImages.length; ++i) {
          stepImages[i].style['background-image'] = 'url("' + stepImages[i].getAttribute('data-src') + '")';
        }
        var emojis = document.querySelectorAll('.faq-question-emoji');
        for (i = 0; i < emojis.length; ++i) {
          emojis[i].src = emojis[i].getAttribute('data-src');
        }
      }

      function checkTotalLoadStatus() {
        if (overlayRemoved) {
          return false;
        }
        var statuses = Object.values(imageLoadStatus);
        var i;
        for (i = 0; i < statuses.length; ++i) {
          if (statuses[i] !== 1) {
            return false;
          }
        }
        overlayRemoved = true;
        // all images loaded, remove overlay and start card animations
        if (overlay) {
          overlay.classList.add('fade-out');
        }
        var i, card, cardContent, appearOrder;
        for (i = 0; i < cardElements.length; ++i) {
          cardContent = cardElements[i].querySelector('.card-content');
          appearOrder = cardElements[i].getAttribute('data-appear-order');
          if (appearOrder) {
            cardContent.classList.add('slide-in', 'slide-in-' + appearOrder);
          }
        }
        var rellax = new Rellax('.rellax');
        // start to load load step images
        loadOtherImages();
      }
      function onImageLoadOrError(index) {
        imageLoadStatus[index] = 1;
        checkTotalLoadStatus();
      }
      function loadImage(index) {
        var img = new Image();
        img.src = photoPath;

        // bind load\error handlers
        imageLoadStatus[index] = 0;

        img.addEventListener('error', onImageLoadOrError.bind(window, index, photoPath));
        img.addEventListener('load', onImageLoadOrError.bind(window, index, photoPath));
      }

      for (i = 0; i < cardsCount; ++i) {
        randomPos = Math.floor(Math.random() * cardsIndices.length);
        cardIndex = cardsIndices[randomPos];
        cardsIndices.splice(randomPos, 1);

        cardsArray = i < femalesCount
          ? window.cardsFemales
          : window.cardsMales;

        person = cardsArray[i];
        cardElem = cardsWrapper.querySelector('.card:nth-of-type(' + cardIndex + ') .card-content');
        if (!cardElem)
          continue;

        genderSuffix = person.gender === 'm' ? 'male' : 'female';
        photoPath = '/images/cards/photo_' + genderSuffix + '_' + person.index + '.jpg';
        cardElem.style['background-image'] = "url('" + photoPath + "')";

        cardNameElem = cardElem.querySelector('.card_name');
        if (cardNameElem) {
          cardNameElem.innerText = person.name[lang] + ', ' + person.age.toString();
        }
        loadImage(cardIndex);
      }
    }
  })();
}